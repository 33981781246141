import { foxid } from './foxid.ts'

export type PlayerType = 'AUDIENCE' | 'CONTESTANT'
export interface Player {
  id: string
  type: PlayerType
  name: string
}

export interface Buzzer {
  playerID: string
  at: number
}

export type CheerType = 'CHEER' | 'BOO' | 'APPLAUSE'
export interface Cheer {
  playerID: string
  type: CheerType
  at: number
}

export type PacketKind = 'PING' | 'PONG' | 'BUZZER' | 'CHEER' | 'PLAYER_STATES' | 'BUZZER_STATES'
export interface Packet {
  kind: PacketKind
  data: string
}

export interface PingC2SPacketData {
  key: string
}
export interface PongS2CPacketData {
  key: string
}

export interface BuzzerC2SPacketData {
}
export interface CheerC2SPacketData {
  type: CheerType
}

export interface PlayerStatesS2CPacketData {
  players: Player[]
}
export interface BuzzerStatesS2CPacketData {
  buzzers: Buzzer[]
  cheers: Cheer[]
}

export type PacketData = {
  'PING': PingC2SPacketData
  'PONG': PongS2CPacketData
  'BUZZER': BuzzerC2SPacketData
  'CHEER': CheerC2SPacketData
  'PLAYER_STATES': PlayerStatesS2CPacketData
  'BUZZER_STATES': BuzzerStatesS2CPacketData
}

const hostApiKey = 's74b03cfj81jr4t7r1gsg2jqvwmf0v95'
const playApiKey = 'ap7tkdzmvrqv2752ng6wvw2k7wf1jgmh'

const wsURL = new URL(window.location.href)
wsURL.protocol = wsURL.protocol === 'https:' ? 'wss://' : 'ws://'
wsURL.username = ''
wsURL.password = ''
wsURL.pathname = '/ws'
wsURL.hash = ''
wsURL.search = ''

export type SocketType = 'host' | 'play'
export interface SocketPlayOptions {
  id: string
  audienceName?: string
  contestantName?: string
}

export type SocketHandlerCallback = <K extends PacketKind>(this: Socket, kind: K, data: PacketData[K]) => void

export type SocketEventCallback<E extends Event> = (this: Socket, event: E) => void

export class Socket {

  handler: SocketHandlerCallback | null

  onopen: SocketEventCallback<Event> | null
  onmessage: SocketEventCallback<MessageEvent> | null
  onclose: SocketEventCallback<CloseEvent> | null
  onerror: SocketEventCallback<Event> | null

  readonly #token: string

  readonly #type: SocketType
  readonly #playOptions: SocketPlayOptions | null

  #pingCount: number
  #reconnectTimeout: number | null

  #ws: WebSocket | null

  constructor(type: 'host')
  constructor(type: 'play', options: SocketPlayOptions)

  constructor(type: SocketType, options?: SocketPlayOptions) {
    if (type === 'play') {
      if (!options || !options.id || (options.audienceName && options.contestantName) || (!options.audienceName && !options.contestantName)) {
        throw new Error('Socket.constructor invalid options')
      }
    } else {
      if (options) {
        throw new Error('Socket.constructor illegal options')
      }
    }

    this.handler = null

    this.onopen = null
    this.onmessage = null
    this.onclose = null
    this.onerror = null

    this.#token = foxid(12)

    this.#type = type
    this.#playOptions = options ? { ...options } : null

    this.#pingCount = 0
    this.#reconnectTimeout = null

    this.#ws = null
    this.#connect()

    setInterval(() => this.#ping(), 10_000)
  }

  isConnected(): boolean {
    return this.#ws && this.#ws.readyState === WebSocket.OPEN
  }

  #connectWithURL(url: URL) {
    this.#ws = new WebSocket(url)
    this.#ws.onopen = this.#onopen.bind(this)
    this.#ws.onmessage = this.#onmessage.bind(this)
    this.#ws.onclose = this.#onclose.bind(this)
    this.#ws.onerror = this.#onerror.bind(this)
  }

  #disconnect() {
    if (this.#ws != null) {
      this.#ws.close()
    }
    this.#ws = null
  }

  #connectHost() {
    const url = new URL(wsURL)

    url.searchParams.append('token', this.#token)
    url.searchParams.append('key', hostApiKey)

    this.#connectWithURL(url)
  }
  #connectPlay() {
    const url = new URL(wsURL)

    url.searchParams.append('token', this.#token)
    url.searchParams.append('key', playApiKey)

    url.searchParams.append('id', this.#playOptions!.id)

    if (this.#playOptions!.audienceName) {
      url.searchParams.append('audienceName', this.#playOptions!.audienceName!)
    }
    if (this.#playOptions!.contestantName) {
      url.searchParams.append('contestantName', this.#playOptions!.contestantName!)
    }

    this.#connectWithURL(url)
  }

  #connect() {
    this.#disconnect()
    if (this.#type === 'host') {
      this.#connectHost()
    } else {
      this.#connectPlay()
    }
  }

  #send<K extends PacketKind>(kind: K, data: PacketData[K]) {
    console.info(`Socket.#send ${kind}`)

    const packet: Packet = { kind, data: JSON.stringify(data) }
    this.#ws.send(JSON.stringify(packet))
  }

  send<K extends PacketKind>(kind: K, data: PacketData[K]) {
    if (this.isConnected()) {
      this.#send(kind, data)
    } else {
      console.warn(`Socket.send silently discarding ${kind}, not connected`)
    }
  }

  #receive(event: MessageEvent) {
    const packet: Packet = JSON.parse(event.data)
    const data: PacketData[PacketKind] = JSON.parse(packet.data)

    if (this.handler != null) {
      console.log(`Socket.#receive ${packet.kind}`)
      this.handler(packet.kind, data)
    } else {
      console.warn(`Socket.#receive ${packet.kind} with no handler`)
    }
  }
  #ping() {
    if (this.isConnected()) {
      this.send('PING', {key: `ping-${this.#token}-${this.#pingCount++}`})
    }
  }

  #reconnect() {
    if (this.#reconnectTimeout == null) {
      this.#reconnectTimeout = setTimeout(() => {
        this.#connect()
        this.#reconnectTimeout = null
      }, 2000 + Math.floor(1000 * Math.random()))
    }
  }

  #onopen(event: Event) {
    console.info("Socket.#onopen", event)
    if (this.onopen != null) {
      this.onopen(event)
    }
    this.#send('PING', { key: `hello-${this.#token}` })
  }

  #onmessage(event: MessageEvent) {
    console.info("Socket.#onmessage", event)
    if (this.onmessage != null) {
      this.onmessage(event)
    }
    this.#receive(event)
  }

  #onclose(event: CloseEvent) {
    console.warn("Socket.#onclose", event)
    if (this.onclose != null) {
      this.onclose(event)
    }
    this.#disconnect()
    this.#reconnect()
  }
  #onerror(event: Event) {
    console.warn("Socket.#onerror", event)
    if (this.onerror != null) {
      this.onerror(event)
    }
    this.#disconnect()
    this.#reconnect()
  }

}
