
const storage = window.localStorage

export class KV {

  readonly name: string

  constructor(name: string) {
    this.name = name
  }

  #convertKey(key: string): string {
    return `__gameshow_${this.name}_${key}`
  }

  get(key: string): any {
    const value = storage.getItem(this.#convertKey(key))
    return value !== '' ? JSON.parse(value) : undefined
  }

  set(key: string, value: any): void {
    if (value === undefined) {
      storage.removeItem(this.#convertKey(key))
    } else {
      storage.setItem(this.#convertKey(key), JSON.stringify(value))
    }
  }

}
